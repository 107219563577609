/* eslint-disable no-unused-vars */

const green100 = "#E8FFF3";
const green200 = "#BEFADA";
const green300 = "#68E9A5";
const green400 = "#17B360";
const green500 = "#16A55A";
const green600 = "#09793E";

const fadedGreen100 = "#B9E4CD";

const blue100 = "#E1EFFF";
const blue200 = "#AED3FD";
const blue300 = "#4698F7";
const blue400 = "#166CD3";
const blue500 = "#0A4D9D";
const blue600 = "#01316B";

const fadedBlue100 = "#9DB8D8";

const orange100 = "#FFEAE2";
const orange200 = "#FCBAA1";
const orange300 = "#F18F69";
const orange400 = "#E56430";
const orange500 = "#BE4717";

const turquoise100 = "#D9FDFE";
const turquoise200 = "#98F3F5";
const turquoise300 = "#3DD6D9";
const turquoise400 = "#2E9EA2";
const turquoise500 = "#0A6D71";

const yellow100 = "#FFF5E3";
const yellow200 = "#FFDC9D";
const yellow300 = "#FFBD48";
const yellow400 = "#E5A42F";
const yellow500 = "#CE8400";

const red100 = "#FFE1E1";
const red200 = "#FDBBBB";
const red300 = "#F27F7F";
const red400 = "#E15757";
const red500 = "#C83939";
const red600 = "#A03232";

const grey100 = "#FAFAFA";
const grey200 = "#EEF1F2";
const grey250 = "#DBDBDB";
const grey300 = "#A5AEB0";
const grey400 = "#6D7475";
const grey500 = "#3C4142";
const grey600 = "#111515";

const primary = "#01588d";

export default {
  defaultFont:
    "Sen, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  defaultFontColor: "#282828",
  name: "Planswell",
  primary: primary,
  deemphasizedPrimary: "#e7f6ee",
  darkPrimary: "#00273F",
  secondary: "#E4A432",
  danger: "#DF5F2B",
  lightDanger: "#E15757",
  altDanger: "#f33535",
  altDangerRGB: "243, 53, 53",
  errorRed: "#D53941",
  overdueBackground: "#FEEBEB",
  disabledRed: "#DA6827",
  errorOrange: "#D15421",
  figureGrey: "#a2aaac",
  validGreen: "#1A73A9",
  validGreenRGB: "23, 166, 92",
  validGrey: "#919191",
  borderGreen: "#00273F",
  deemphasizedGrey: "#D0D0D0",
  primaryGreen: "#16A55A",
  secondaryGreen: "#0DA65A",
  alternativeGreen: "#01588D",
  assumptionsGreen: "##339966",
  backgroundGreen: "#01588d",
  loaderGreen: "#01588d",
  profileGreen: "#0a802f",
  hoverHighlight: "#e1fff0",
  borrow: "#D19747",
  protect: "#DA6827",
  investor: "#4E906C",
  debt: "#DABD27",
  expenses: "#4E8890",
  borrowText: "#FFBD48",
  protectText: "#BE4717",
  investorText: "#01316B",
  debtText: "#CE8400",
  investorIcon: "#16A55A",
  protectIcon: "#8C50B2",
  borrowIcon: "#881866",
  bannerGrey: "#6D7475",
  bannerGreen: "#D6EEE1",
  toastGreen: "#D6EEE1",
  bannerColor: primary,
  bannerText: "#4E906C",
  white: "white",
  whiteRGB: "255,255,255",
  fadeWhite: "rgba(255, 255, 255, 0.5)",
  alternateWhite: "#F4F9F7",
  blueWhite: "#EEF4FF",
  black: "black",
  blackRGB: "0,0,0",
  fadeBlack: "rgba(0, 0, 0, 0.5)",
  textGrey: "#747480",
  darkGrey: "rgb(109, 116, 117)",
  darkGreyRGB: "60, 65, 66",
  darkerGrey: "#111111",
  lightGrey: "#CCCCCC",
  lighterGrey: "#DDDDDD",
  activeGrey: "#494949",
  inactiveGrey: "#eef1f2",
  midGrey: "#404040",
  midGreyRGB: "64,64,64",
  footerGrey: "#F3F3F3",
  tableBorderGrey: "#AAAAAA",
  progressGrey: "#ECECEC",
  netWorth: "#1A73A9",
  alternateSectionGrey: "#D0D0D0",
  placeholderGrey: "#D9D9D9",
  placeholderGreyRGB: "217,217,217",
  inputBorderGrey: "#797979",
  midLightGrey: "#b0b0b0",
  ctaBlue: "#1A73A9",
  blue: "#01588D",
  textAreaText: "#6C7375",
  headerText: "#3d4243",
  headerTextRGB: "61, 66, 67",
  checkBorder: "#cacece",
  checkBorderChecked: "#adb8c0",
  checkTextChecked: "#99a1a7",
  hoverText: "#e4a432",
  tealText: "#0a6d71",
  dispoRed: "#fdd9c4",
  dispoGreen: "#e7fef2",
  dispoGreenRGB: "231, 254, 242",
  dispoBlue: "#dce9f8",
  dispoYellow: "#ffe5b8",
  deliveryPurple: "#DA6827",
  confettiBlue: "#0040A8",
  confettiBlue2: "#387FF2",
  confettiGold: "#D2F207",
  confettiGold2: "#91A800",
  firstTimeBannerGradient: "#fdf7e8, #fbf7e5, #f9f8e3, #f5f8e0, #f1f9df",
  marketingPlan: "#D9FDFE",
  autopilotPlan: "#FFEAE2",
  greenPlan: "#BEFADA",
  goldPlan: "#FFF5E3",
  platinumPlan: "#E7E7E7",
  noInsightsGrey: "#EEF4FF",
  insightsInactive: "#E2FBE8",
  insightsCTA: "#4E906C",
  noInsightsCTA: "#01588D",
  deliveryGreen: "#4E906C",
  deliveryBorderGreen: "#DA6827",
  deliveryBorderGreenRGB: "218, 104, 39",
  deliveryOrange: "#DABD27",
  deliveryRed: "#DA6827",
  legalGreen: "#7FB5D6",
  accordianTitleBackground: "#ebeeec",
  inputText: "#1cab63",
  backGrey: "#3e4344",
  effectGrey: "#2e2e2e",
  negativeText: "#DA6827",
  oopsBackground: "#f3f6f4",
  document: "#cfd6d7",
  surveyPanelButton: "#eefff5",
  bootcamp: "#17763D",
  greenBack: "#4E906C",
  deliveryChartColors: ["#0A4D9D", "#AED3FD", "#499AFC"],
  tooltip: "#3e4343",
  timeText: "#7bc78e",
  advisorViewDropdownText: "#0B1F37",
  calendarTile: "#76baff",
  calendarTileHover: "#a9d4ff",
  calendarTileActive: "#006edc",
  calendarTileActiveHover: "#1087ff",
  calendarTileNow: "#ffff76",
  calendarTileNowHover: "#ffffa9",
  chartEventRGB: "3, 19, 72",
  chartLifestyleFillRGB: "240, 246, 233",
  chartLifestyleStrokeRGB: "166,22,130",
  chartDebtRGB: "166,18,2",
  chartRentRGB: "140,26,7",
  chartMortgageRGB: "168,92,21",
  chartPreRetirementIncomeRGB: "1,49,107",
  chartPrimaryOASRGB: "56,2,166",
  chartPrimaryCPPRGB: "49,5,140",
  chartPrimaryPensionRGB: "43,7,115",
  chartPrimaryAnnuitiesRGB: "36,10,89",
  chartSecondaryOASRGB: "167,23,130",
  chartSecondaryCPPRGB: "141,16,109",
  chartSecondaryPensionRGB: "115,10,89",
  chartSecondaryAnnuitiesRGB: "89,3,68",
  chartPrimaryNonRegRGB: "14,128,69",
  chartPrimaryTFSARGB: "12,111,60",
  chartPrimaryIRARGB: "10,94,50",
  chartPrimaryRRSPRGB: "8,77,41",
  chartSecondaryNonRegRGB: "2,112,191",
  chartSecondaryTFSARGB: "2,102,174",
  chartSecondaryIRARGB: "1,92,157",
  chartSecondaryRRSPRGB: "1,82,140",
  chartRESPRGB: "75,2,166",
  chartCashRGB: "52,1,115",
  chartShortfallRGB: "198,40,23",
  chartEmergencyFund: "rgba(192, 0, 192, 0.5)",
  chartLabelFill: "rgba(17, 21, 21, 0.5)",
  chartLabelBackground: "rgba(232, 232, 232, 0.8)",
  toggleGrey: "rgba(120, 120, 128, 0.4)",
  disabledGrey: "rgba(136, 143, 145, 0.3)",
  inputShadow: "rgba(184, 182, 184, 0.34)",
  chartMarkerRGB: "151,151,151",
  investmentRGB: "22,106,207",
  debtRGB: "255,189,72",
  insuranceRGB: "223,95,43",
  primaryRGB: "1, 88, 141",
  teamGradient: "linear-gradient(to bottom, #42c658, #20c8e0)",
  bannerGradient: "linear-gradient(260deg, #32c5ff, #b620e0)",
  containerBorderRadius: "6px",
  footerHeight: "150px",
  footerShadow: "rgba(93, 93, 93, 0.10196)",
  boxShadow: "rgba(60, 65, 66, 0.15) 0px 2px 28px -2px",
  progressOffset: "5.5rem",
  discoveryProgressOffset: "2.5rem",
  backdropImage: "/images/nobg.png",
  backdropImageMobile: "/images/nobg.png",
  backdropImageNew: "/images/nobg.png",
  backdropImageNew2: "/images/nobg.png",
  backdropImageMobileNew: "/images/nobg.png",
  navbarLinks: [
    { text: "Privacy Policy", link: process.env.REACT_APP_PRIVACY_POLICY_URL },
    {
      text: "Terms of Service",
      link: process.env.REACT_APP_TERMS_OF_SERVICE_URL,
    },
  ],
  dashboardImage:
    "url('/images/nobg.png'), linear-gradient(to bottom, #F5FBF8 260px, transparent 0)",
  insuranceImage:
    "url('/images/nobg.png'), linear-gradient(to bottom, #FEF6F3 260px, transparent 0)",
  debtImage:
    "url('/images/nobg.png'), linear-gradient(to bottom, #FFF6E7 260px, transparent 0)",
  lightGreyBackground: "#FBFCFC",
  loader: {
    src: "/themes/fatfire/loader.gif",
    placeholder: "/themes/fatfire/loader-static.gif",
    alt: "loading",
  },
  inPageLoader: {
    src: "/themes/fatfire/inpage_animation.gif",
    placeholder: "/themes/fatfire/inpage_p_static.png",
    alt: "loading",
  },
  logo: {
    dark: "/themes/fatfire/fatfire_logo_dark.svg",
    light: "/themes/fatfire/fatfire_logo.svg",
    height: {
      small: "1.3rem",
      medium: "1.625rem",
      large: "35px",
    },
    width: {
      small: "5.869rem",
      medium: "7.336rem",
      large: "158px",
    },
  },
  resetPassword: {
    backgroundImage: null,
    backgroundPosition: "bottom left",
    backgroundSize: "100% auto",
  },
  surveyGeneralBackcolor: "rgba(255, 255, 255, 0)",
  surveyGeneralBackcolorDark: "rgba(255, 255, 255, 1)",
  surveyGeneralBackcolorDim: "rgba(255, 255, 255, 0)",
  surveyGeneralBackcolorDimLight: "rgba(255, 255, 255, 1)",
  surveyGeneralBackcolorDimDark: "rgba(255, 255, 255, 1)",
  surveyGeneralForecolor: "rgba(0, 0, 0, 1)",
  surveyGeneralForecolorLight: "rgba(0, 0, 0, 1)",
  surveyGeneralDimForecolor: "rgba(64, 64, 64, 1)",
  surveyGeneralDimForecolorLight: "rgba(0, 0, 0, 1)",
  surveyPrimaryBackcolor: "rgba(0, 0, 0, 1)",
  surveyPrimaryBackcolorLight: "rgba(255, 255, 255, 1)",
  surveyPrimaryBackcolorDark: "rgba(64, 64, 64, 1)",
  surveyPrimaryForecolor: "rgba(255, 255, 255, 1)",
  surveyPrimaryForecolorLight: "rgba(32, 32, 32, 0.25)",
  surveyBaseUnit: "12px",
  surveyCornerRadius: "4px",
  surveySecondaryBackcolor: "rgba(5, 130, 63, 1)",
  surveySecondaryBackcolorLight: "rgba(255, 152, 20, 0.1)",
  surveySecondaryBackcolorSemiLight: "rgba(255, 152, 20, 0.25)",
  surveySecondaryForecolor: "rgba(48, 48, 48, 1)",
  surveySecondaryForecolorLight: "rgba(48, 48, 48, 0.25)",
  surveyShadowSmall: "none",
  surveyShadowSmallReset: "0px 0px 0px 0px rgba(0, 0, 0, 1)",
  surveyShadowMedium: "0px 0px 0px 2px rgba(0, 0, 0, 1)",
  surveyShadowLarge: "0px 6px 0px 0px rgba(0, 0, 0, 1)",
  surveyShadowInner:
    "0px 0px 0px 2px rgba(0, 0, 0, 1), 0px -2px 0px 2px rgba(0, 0, 0, 1)",
  surveyShadowInnerReset:
    "0px 0px 0px 0px rgba(0, 0, 0, 1), 0px 0px 0px 0px rgba(0, 0, 0, 1)",
  surveyBorderLight: "rgba(232, 192, 51, 1)",
  surveyBorderDefault: "rgba(0, 0, 0, 0)",
  surveyBorderInside: "rgba(255, 255, 255, 0.08)",
  surveySpecialRed: "rgba(254, 76, 108, 1)",
  surveySpecialRedLight: "rgba(254, 76, 108, 0.1)",
  surveySpecialRedForecolor: "rgba(48, 48, 48, 1)",
  surveySpecialGreen: "rgba(36, 197, 164, 1)",
  surveySpecialGreenLight: "rgba(36, 197, 164, 0.1)",
  surveySpecialGreenForecolor: "rgba(48, 48, 48, 1)",
  surveySpecialBlue: "rgba(91, 151, 242, 1)",
  surveySpecialBlueLight: "rgba(91, 151, 242, 0.1)",
  surveySpecialBlueForecolor: "rgba(48, 48, 48, 1)",
  surveySpecialYellow: "rgba(5, 130, 63, 1)",
  surveySpecialYellowLight: "rgba(255, 152, 20, 0.1)",
  surveySpecialYellowForecolor: "rgba(48, 48, 48, 1)",
  surveyArticleFontXxLargeTextDecoration: "none",
  surveyArticleFontXxLargeFontWeight: "700",
  surveyArticleFontXxLargeFontStyle: "normal",
  surveyArticleFontXxLargeFontStretch: "normal",
  surveyArticleFontXxLargeLetterSpacing: "0",
  surveyArticleFontXxLargeLineHeight: "64px",
  surveyArticleFontXxLargeParagraphIndent: "0px",
  surveyArticleFontXxLargeTextCase: "none",
  surveyArticleFontXLargeTextDecoration: "none",
  surveyArticleFontXLargeFontWeight: "700",
  surveyArticleFontXLargeFontStyle: "normal",
  surveyArticleFontXLargeFontStretch: "normal",
  surveyArticleFontXLargeLetterSpacing: "0",
  surveyArticleFontXLargeLineHeight: "56px",
  surveyArticleFontXLargeParagraphIndent: "0px",
  surveyArticleFontXLargeTextCase: "none",
  surveyArticleFontLargeTextDecoration: "none",
  surveyArticleFontLargeFontWeight: "700",
  surveyArticleFontLargeFontStyle: "normal",
  surveyArticleFontLargeFontStretch: "normal",
  surveyArticleFontLargeLetterSpacing: "0",
  surveyArticleFontLargeLineHeight: "40px",
  surveyArticleFontLargeParagraphIndent: "0px",
  surveyArticleFontLargeTextCase: "none",
  surveyArticleFontMediumTextDecoration: "none",
  surveyArticleFontMediumFontWeight: "700",
  surveyArticleFontMediumFontStyle: "normal",
  surveyArticleFontMediumFontStretch: "normal",
  surveyArticleFontMediumLetterSpacing: "0",
  surveyArticleFontMediumLineHeight: "32px",
  surveyArticleFontMediumParagraphIndent: "0px",
  surveyArticleFontMediumTextCase: "none",
  surveyArticleFontDefaultTextDecoration: "none",
  surveyArticleFontDefaultFontWeight: "400",
  surveyArticleFontDefaultFontStyle: "normal",
  surveyArticleFontDefaultFontStretch: "normal",
  surveyArticleFontDefaultLetterSpacing: "0",
  surveyArticleFontDefaultLineHeight: "28px",
  surveyArticleFontDefaultParagraphIndent: "0px",
  surveyArticleFontDefaultTextCase: "none",
  scoreCardChartAxis: "var(--steel600)",
  scoreCardInvestment: "var(--blue800)",
  scoreCardDebt: "var(--chart010)",
  scoreCardMortgage: "var(--yellow600)",
  transparent: "transparent",
  vista: "/images/nobg.png",
  vistaPng: "/images/nobg.png",
  vistaLeft: "/images/nobg.png",
  vistaRight: "/images/nobg.png",
  vistaLeft3x: "/images/nobg.png",
  vistaRight3x: "/images/nobg.png",
  greenVista: "/images/nobg.png",
  greenLeftRight: "/images/nobg.png",
  vistaMobile: "/images/nobg.png",
  checkIcon: "/images/icon-checked.svg",
  errorImage: "/images/fatfire/bearmail1.png",
  notFoundImage: "/images/fatfire/404.png",
  headerImage: "/images/nobg.png",
  badgeReview: "/images/fatfire/badge-review.png",
  badgeSave: "/images/fatfire/badge-save.png",
  badgePiggy: "/images/fatfire/badge-piggy.png",
  badgeWait: "/images/fatfire/badge-wait.png",
  badgeRefinance: "/images/fatfire/badge-refinance.png",
  badgeProtected: "/images/fatfire/badge-protected.png",
  badgeUnprotected: "/images/fatfire/badge-unprotected.png",
  badgeHouse: "/images/fatfire/badge-house.png",
  badgeSpend: "/images/fatfire/badge-spend.png",
  badgeCut: "/images/fatfire/badge-cut.png",
  celebrationImage: "/images/fatfire/rewards_party.png",
  congratsImage: "/images/fatfire/congrats.png",
  bookACallHeaderImage: "/images/fatfire/credit-card.png",
  bookACallHeaderImageV4: "/images/fatfire/clipboard.png",
  bookACallPDFHeaderImage: "/images/fatfire/clipboard.png",
  builderImage: "/images/fatfire/clipboard.png",
  noAssetsImage: "/images/fatfire/badge-look.png",
  bookACallHeaderBackground: "#4da361",
  bookACallCancel: "#a03232",
  accountButtonHover: "#131a17",
  accountButtonActive: "#2a2b2b",
  planViewTabBar: blue100,
  planViewTab: blue100,
  planViewAction: blue500,
  planViewActionDisabled: fadedBlue100,
  planViewAddSpouse: blue300,
  planViewAddItem: blue500,
  planViewAddItemDisabled: fadedBlue100,
  planViewSave: green500,
  planViewSaveDisabled: fadedGreen100,
  planViewTabSelected: "white",
  planViewInputs: grey200,
  planViewInputsField: grey100,
  planViewInputsFieldBorder: "none",
  planViewInputsLabel: grey400,
  planViewInputSectionHeader: grey250,
  planViewInputSectionBorder: grey200,
  planViewInputTab: grey250,
  planViewInputTabSelected: grey200,
  planViewDocumentTab: "white",
  planViewDocumentTabText: "black",
  planViewDocumentTabSelected: green500,
  planViewDocumentTabSelectedText: "white",
  planViewNewTag: green500,
  planViewDeletedTag: red500,
  planViewEmptyList: "black",
  planViewEmptyListBackground: grey100,
  planViewInputCardField: "white",
  planViewInputCardFieldBorder: `solid 2px ${grey250}`,
  planViewInputListCard: grey100,
  planViewInputListCardBorder: grey250,
  planViewSidebarControl: grey400,
  dropdownText: "black",
  dropdownTextDisabled: grey250,
  dropdownBackground: "white",
  dropdownBackgroundHover: blue100,
  netWorthAssetTaxDeferred: green600,
  netWorthAssetTaxDeferredConversion: "#184A30",
  netWorthAssetTaxFree: green500,
  netWorthAssetHome: green400,
  netWorthAssetTaxable: green300,
  netWorthAssetEducation: green200,
  netWorthAssetSavings: green200,
  netWorthDebtMortgage: red300,
  netWorthDebtCarLoan: red200,
  netWorthDebtCreditCard: red600,
  netWorthDebtLineOfCredit: red400,
  netWorthDebtOtherLoan: red500,
  netWorthDebtCottage: red300,
  scoreLine1: red300,
  scoreLine2: orange400,
  scoreLine3: yellow300,
  scoreLine4: turquoise400,
  scoreLine5: green500,
  planViewBorder: grey200,
  fieldErrorBackground: red100,
  fieldErrorBorder: `solid 2px ${red300}`,
  fieldErrorText: red500,
  modalOk: green500,
  modalDanger: red500,
  bookCTA: "#013B5E",
};
